import * as React from "react";
import { Admin, Resource } from 'react-admin';
import jsonServerProvider from "./providers/jsonServerProvider";
import authProvider from './providers/authProvider';
import { API_URL } from "./utils/constants";
import TranslationsList from "./render/views/translations/List";
import TranslationsCreate from "./render/views/translations/Create";
import TranslationsEdit from "./render/views/translations/Edit";
import SubcategoriesList from "./render/views/subcategories/List";
import SubcategoriesCreate from "./render/views/subcategories/Create";
import SubcategoriesEdit from "./render/views/subcategories/Edit";
import MainCategoriesList from "./render/views/main-categories/List";
import MainCategoriesCreate from "./render/views/main-categories/Create";
import MainCategoriesEdit from "./render/views/main-categories/Edit";
import SkillsList from "./render/views/skills/List";
import SkillsCreate from "./render/views/skills/Create";
import SkillsEdit from "./render/views/skills/Edit";
import ToolsList from "./render/views/tools/List";
import ToolsCreate from "./render/views/tools/Create";
import ToolsEdit from "./render/views/tools/Edit";
import CandidatesList from "./render/views/candidates/List";
import CandidatesCreate from "./render/views/candidates/Create";
import UsersList from "./render/views/users/List";
import UsersCreate from "./render/views/users/Create";
import UsersEdit from "./render/views/users/Edit";
import CandidatesEdit from "./render/views/candidates/Edit";
import WantedCandidatesList from "./render/views/wanted-candidates/List";
import WantedCandidatesEdit from "./render/views/wanted-candidates/Edit";
import SavedCandidatesList from "./render/views/saved-candidates/List";
import SavedCandidatesEdit from "./render/views/saved-candidates/Edit";
import DreamCandidatesList from "./render/views/dream-candidates/List";
import DreamCandidatesEdit from "./render/views/dream-candidates/Edit";
import CaseCategoriesList from "./render/views/case-categories/List";
import CaseCategoriesCreate from "./render/views/case-categories/Create";
import CaseCategoriesEdit from "./render/views/case-categories/Edit";
import CasesList from "./render/views/cases/List";
import CasesCreate from "./render/views/cases/Create";
import CasesEdit from "./render/views/cases/Edit";
import FaqList from "./render/views/faq/List";
import FaqCreate from "./render/views/faq/Create";
import FaqEdit from "./render/views/faq/Edit";
import TrackingList from "./render/views/tracking/List";
import TrackingUsersEdit from "./render/views/tracking-users/Edit";
import TrackingGlobalEdit from "./render/views/tracking-global/Edit";
import IntroVideoEdit from "./render/views/intro-video/Edit";
import './App.scss';

const App = () => (
  <Admin
    dataProvider={jsonServerProvider(API_URL)}
    authProvider={authProvider}
  >
    <Resource name="translations" list={TranslationsList} create={TranslationsCreate} edit={TranslationsEdit} />
    <Resource name="main-categories" list={MainCategoriesList} create={MainCategoriesCreate} edit={MainCategoriesEdit} options={{ label: 'Main Categories' }} />
    <Resource name="subcategories" list={SubcategoriesList} create={SubcategoriesCreate} edit={SubcategoriesEdit} />
    <Resource name="skills" list={SkillsList} create={SkillsCreate} edit={SkillsEdit} options={{ label: 'Format' }}/>
    <Resource name="tools" list={ToolsList} create={ToolsCreate} edit={ToolsEdit} options={{ label: 'Status' }}/>
    <Resource name="candidates" list={CandidatesList} create={CandidatesCreate} edit={CandidatesEdit} options={{ label: 'Documents' }}/>
    <Resource name="user-list" list={UsersList} create={UsersCreate} edit={UsersEdit} options={{ label: 'Users' }} />
    <Resource name="wanted-candidate-list" list={WantedCandidatesList} edit={WantedCandidatesEdit} options={{ label: 'Wanted Documents' }} />
    <Resource name="saved-candidate-list" list={SavedCandidatesList} edit={SavedCandidatesEdit} options={{ label: 'Favorite Documents' }} />
    <Resource name="dream-candidate-list" list={DreamCandidatesList} edit={DreamCandidatesEdit} options={{ label: 'My Documents' }} />
    <Resource name="case-categories" list={CaseCategoriesList} create={CaseCategoriesCreate} edit={CaseCategoriesEdit} options={{ label: 'Academy Categories' }} />
    <Resource name="case-list" list={CasesList} create={CasesCreate} edit={CasesEdit} options={{ label: 'Academy' }} />
    <Resource name="faqs" list={FaqList} create={FaqCreate} edit={FaqEdit} options={{ label: 'FAQ' }} />
    <Resource name="tracking" list={TrackingList} options={{ label: 'Data Files' }} />
    <Resource name="tracking-users" edit={TrackingUsersEdit} options={{ label: 'Users Data' }} />
    <Resource name="tracking-global" edit={TrackingGlobalEdit} options={{ label: 'Global Data' }} />
    <Resource name="intro-video" list={IntroVideoEdit} options={{ label: 'Intro Video' }} />
  </Admin>
);

export default App;
